import {
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useUsersSocialOktaDomainCreate } from "../../api-client/orval/endpoints";
import { useRouter } from "next/router";

const schema = Yup.object({
  email: Yup.string()
    .email("Must be a valid Email")
    .required("Email is required"),
});

type FormData = Yup.InferType<typeof schema>;

const OktaDomainForm = ({ onBack }: { onBack: Function }) => {
  const router = useRouter();
  const [error, setError] = useState("");

  const { mutate: getOktaDomain, isLoading } = useUsersSocialOktaDomainCreate({
    mutation: {
      onError: (error) => {
        setError(
          "Okta not connected for this email. Please try again or contact support."
        );
      },
      onSuccess: (data) => {
        router.replace(
          `/sign-in/okta?oktaDomain=${encodeURIComponent(data.domain!)}`
        );
      },
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormData) => {
    setError("");
    getOktaDomain({
      data,
    });
  };

  const handleBack = () => {
    onBack();
  };

  return (
    <Card
      as="form"
      p="24px"
      bg="white"
      display={"flex"}
      flexDir={"column"}
      gap="16px"
      w="280px"
      onSubmit={handleSubmit(onSubmit)} // Binding the form's onSubmit
    >
      <Flex gap="16px" alignItems={"center"} justifyContent={"center"}>
        <Image
          src="/assets/okta.png"
          height="24px"
          aria-label="candidate.fyi logo"
        />
        <Text>Sign in with Okta</Text>
      </Flex>
      <FormControl isInvalid={!!errors.email}>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          placeholder="Enter your email..."
          {...register("email")} // Connecting the input field with useForm
        />
        {errors.email && (
          <Text color="red.500" fontSize="sm">
            {(errors.email as any).message}
          </Text>
        )}
      </FormControl>
      <Button isLoading={isLoading} type="submit" colorScheme="deepBlue">
        Sign In
      </Button>
      {error && (
        <Text color="red.500" fontSize="sm">
          {error}
        </Text>
      )}
      <Button size={"xs"} variant={"link"} onClick={handleBack}>
        Use another provider
      </Button>
    </Card>
  );
};

export default OktaDomainForm;
